<!--
 * @Description: 搜索栏目
 * @Author: zhang zhen
 * @Date: 2023-02-03 15:29:21
 * @LastEditors: zhang zhen
 * @LastEditTime: 2023-10-08 22:24:14
 * @FilePath: /page-sass/src/components/tools/searchBar.vue
-->
<template>
  <div>
    <div class="searchBar" id="InputContainer">
      <a-input-search :placeholder="placeholder" size="large" class="searchInput" allowClear>
        <a-select
          default-value
          slot="addonBefore"
          style="width: 78px;"
          :getPopupContainer="container"
        >
          <img src="~@/assets/arrow-down.png" alt class="arrow" slot="suffixIcon" />
          <a-select-option value>全部</a-select-option>
          <a-select-option value="1">生产商</a-select-option>
          <a-select-option value="2">服务商</a-select-option>
        </a-select>
        <a-button type="primary" size="large" slot="enterButton">
          <div class="search-btn">
            <!-- <img src="~@/assets/home/search.png" alt="" /> -->
            <span>搜索</span>
          </div>
        </a-button>
      </a-input-search>
    </div>

    <div class="funBar">
      <div class="funBarItem" @click="handleNavigatePage('product')">
        <!-- <img src="~@/assets/home/fun01.png" class="funBarItemImg" /> -->
        <div class="funBarItemInfo">
          <div class="funTitle">
            <p style="margin: 0;">寻找生产商需求</p>
            <p style="margin: 0;" class="goBtn">
              <span>GO</span>
              <img src="~@/assets/up-base-outline-pc.png" class="arrow-btn" />
            </p>
          </div>
          <p class="funTxt">已有配材或样品时使用</p>
        </div>
      </div>
      <div class="funBarItem" @click="handleNavigatePage('package')">
        <!-- <img src="~@/assets/home/fun02.png" class="funBarItemImg" /> -->
        <div class="funBarItemInfo">
          <div class="funTitle">
            <p style="margin: 0;">寻找配材需求</p>
            <p style="margin: 0;" class="goBtn">
              <span>GO</span>
              <img src="~@/assets/up-base-outline-pc.png" class="arrow-btn" />
            </p>
          </div>
          <p class="funTxt">已有设计方案时使用</p>
        </div>
      </div>
      <div class="funBarItem" @click="handleNavigatePage('plan')">
        <!-- <img src="~@/assets/home/fun03.png" class="funBarItemImg" /> -->
        <div class="funBarItemInfo">
          <div class="funTitle">
            <p style="margin: 0;">寻找设计需求</p>
            <p style="margin: 0;" class="goBtn">
              <span>GO</span>
              <img src="~@/assets/up-base-outline-pc.png" class="arrow-btn" />
            </p>
          </div>
          <p class="funTxt">需要整体设计方案时使用</p>
        </div>
      </div>
      <!-- <div class="funBarItem" @click="handleOpenRequire">
        <img src="~@/assets/home/fun04.png" class="funBarItemImg" />
        <div class="funBarItemInfo">
          <p class="funTitle">发布采购需求</p>
          <p class="funTxt" style="opacity: 0;">文案文案文案文案文案</p>
        </div>
      </div>-->
    </div>

    <!-- 导航 -->
    <FormNavigateModal ref="FormNavigateModal" />
  </div>
</template>

<script>
import FormNavigateModal from '@/components/tools/formNavigateModal'
import { mapGetters } from 'vuex'
import { postQueryAction } from '@/api/manage'

export default {
  components: {
    FormNavigateModal
  },
  name: 'searchBar',
  props: {
    placeholder: {
      type: String,
      default: '搜索生产商/方案/产品'
    }
  },
  computed: {
    ...mapGetters(['isLogin'])
  },
  data() {
    return {
      container: () => document.querySelector('#InputContainer')
    }
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    //0831
    handleNavigatePage(formType) {
      if (this.setUserInfo().status != 1) {
          this.$message.warning('未完善用户信息, 请先完善信息')
      } else {
        postQueryAction('/business/queryByBusinessId', { businessId: this.setUserInfo().businessId }).then((res) => {
        const { success, data } = res
        if (success) {
          if (data.status == 1) {
            this.$router.push({
              path: '/PublishingRequirements',
              query: {
                formType
              }
            })
          } else {
            this.$message.warning(data.status == -1 ? '组织信息审核驳回， 不能发布需求' : '组织信息待审核，不能发布需求')
          }
        }
      })

      }
    },

    /* 跳转需求 */
    handleOpenRequire() {
      this.$refs.FormNavigateModal.handleOpenView()
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .ant-input-group-addon {
  background-color: white;
  border: none!important;
}
::v-deep .ant-input {
  border: 1px solid white;
}
.searchBar {
  width: 100%;
  height: auto;
  padding: 40px 0 80px 0;
  background-image: url('~@/assets/dbbg1.png');
  background-size: cover;
  background-position: -2px center;
  background-repeat: no-repeat;
  position: relative;
  .flexLayout(@direction: row; @justifyContent: center; @alignItem: center);
  // max-width: 990px;
  // margin-bottom: 25px;
  .searchBtn {
    border-radius: 8px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 40px !important;
    background: #FF6026 !important;
    border: 1px solid #FF6026 !important;
  }
  .searchInput {
    width: 667px;
    border-radius: 8px;
    overflow: hidden;
  }
}
::v-deep .ant-input-lg {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 14px;
}
.arrow {
  width: 10px;
  height: 6px;
  margin-top: 3px;
}
.funBar {
  // width: 1288px;
  // min-width: 1288px;

  width: 100%; //0724
  margin: 0 auto;

  box-sizing: border-box;
  margin-bottom: 15px;
  margin-top: -45px;
  background: #ffffff;
  // border-radius: 4px; //0724
  position: relative;
  z-index: 9;
  display: flex;
  justify-content: center;
  .funBarItem {
    display: flex;
    align-items: center;
    justify-content: center;
    // flex: 1;
    width: 25%;
    padding: 15px 0;
    cursor: pointer;
    &:not(:last-of-type) {
      // border-right: 1px solid #efefef;
    }
    .funBarItemImg {
      width: 48px;
      height: 48px;
    }
    .funBarItemInfo {
      .funTitle {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #131212;
        margin: 0;
        .goBtn {
          width: 35px;
          height: 20px;
          line-height: 28px;
          border-radius: 5px;
          font-size: 12px;
          background-color: #FF6026;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 20px !important;
        }
        .arrow-btn {
          width: 9px;
          height: 9px;
        }
      }
      .funTxt {
        margin: 5px 0 0 0;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: #8d8e99;
      }
    }
  }
}
::v-deep .ant-select-selection-selected-value {
  color: #131212;
}

::v-deep .ant-input-lg {
  border-left: 1px solid #EFEFEF;
}

.search-btn {
  display: flex;
  align-items: center;
  // width: 80px;
  padding: 0 15px;
  font-weight: 600;
  text-align: center;
  img {
    width: 16px;
    margin-right: 4px;
  }
}

::v-deep .ant-btn-primary{
  text-shadow: none!important;
}
</style>
