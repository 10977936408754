<!--
 * @Description: 工作台的头部
 * @Author: zhang zhen
 * @Date: 2023-05-12 17:31:40
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-08-12 23:23:18
 * @FilePath: /page-sass/src/components/page/workSpaceHeader.vue
-->
<template>
  <div class="workSpaceHeader">
    <div class="left-icon" @click="handleChangeMenus">
      <a-icon type="menu-fold" v-if="!collapsed" title="收起" />
      <a-icon type="menu-unfold" v-else title="展开" />
    </div>
    <div class="right-info">
      <!-- <router-link tag="div" to="/" class="nav-link">
        <img src="~@/assets/newHomePage/back.png" width="16px" />
      </router-link> -->
      <router-link tag="div" to="/" class="link-text backToHome">返回首页</router-link>
      <div class="link-text sendInfo" @click="handleNavigatePage('product')"
           v-if="business?.roleType === BusinessRoleType.PURCHASER">发布需求
      </div>
      <router-link tag="div" to="/messageManagement/messageList" class="nav-link notice-areas">
        <a-popover placement="bottomRight" :autoAdjustOverflow="true" :arrowPointAtCenter="false"
                   overlayClassName="header-notice-wrapper"
                   :overlayStyle="{ width: '364px', top: '50px' }">
          <template slot="content">
            <div class="header-title">
              <img src="/notice_icon.png" alt="" class="alarm">
              <span>消息中心</span>
            </div>
            <div class="notice-content-item">
              <span class="left-label">
                <span class="label">系统消息</span>
                <a-badge :count="countInfo.msgCategory_1 || 0" :overflow-count="999"
                         :number-style="{ backgroundColor: '#EE4261' }" v-if="countInfo.msgCategory_1" />
                <a-badge v-else :showZero="true" count="0" :number-style="{
      backgroundColor: '#EFEFEF',
      color: 'rgba(0, 0, 0, .25)',
      boxShadow: '0 0 0 1px #d9d9d9 inset',
    }" />
              </span>
              <img src="/showInfo.png" alt="" class="showInfo" @click="handleJump(1)">
            </div>
            <div class="notice-content-item">
              <span class="left-label">
                <span class="label">服务消息</span>
                <a-badge :count="countInfo.msgCategory_2 || 0" :overflow-count="999"
                         :number-style="{ backgroundColor: '#EE4261' }" v-if="countInfo.msgCategory_2" />
                <a-badge v-else :showZero="true" count="0" :number-style="{
      backgroundColor: '#EFEFEF',
      color: 'rgba(0, 0, 0, .25)',
      boxShadow: '0 0 0 1px #d9d9d9 inset',
    }" />
              </span>
              <img src="/showInfo.png" alt="" class="showInfo" @click="handleJump(2)">
            </div>
            <div class="notice-content-item">
              <span class="left-label">
                <span class="label">客户消息</span>
                <a-badge :count="countInfo.msgCategory_3 || 0" :overflow-count="999"
                         :number-style="{ backgroundColor: '#EE4261' }" v-if="countInfo.msgCategory_3" />
                <a-badge v-else :showZero="true" count="0" :number-style="{
      backgroundColor: '#EFEFEF',
      color: 'rgba(0, 0, 0, .25)',
      boxShadow: '0 0 0 1px #d9d9d9 inset',
    }" />
              </span>
              <img src="/showInfo.png" alt="" class="showInfo" @click="handleJumpChat">
            </div>
          </template>
          <a-badge :count="messageCount" :number-style="{ backgroundColor: '#EE4261' }" :overflow-count="999">
            <div class="link-text">消息</div>
          </a-badge>
        </a-popover>
      </router-link>
      <Menus :showEntry="false" class="noLeft" />
    </div>
  </div>
</template>

<script>
import Menus from '@/components/tools/menus.vue'
import SendMethods from '@/mixins/sendMethods.js'
import { mapState, mapActions } from 'vuex'
import { getAction } from '@/api/manage.js'
import Notice from '@/mixins/notice'
import { getCurBusiness } from '@/util/utils'
import { BusinessRoleType } from '@/util/constant'

export default {
  name: 'workSpaceHeader',
  mixins: [SendMethods, Notice],
  components: {
    Menus
  },
  data() {
    return {
      collapsed: false,
      business: {}
    }
  },
  created() {
    this.business = getCurBusiness()
  },
  computed: {
    BusinessRoleType() {
      return BusinessRoleType
    },
    ...mapState({
      messageList: (state) => state.messageList, // 消息列表
      messageCount: (state) => state.messageCount // 平台信息
    })
  },
  methods: {
    ...mapActions(['deleteMessageList']),
    handleJump(msgCategory) {
      this.$router.push({
        path: '/messageManagement/messageList',
        query: {
          msgCategory
        }
      })
    },
    /* 折叠菜单 */
    handleChangeMenus() {
      this.collapsed = !this.collapsed
      this.$emit('changeMenus', this.collapsed)
    },
    handleSendRequirement() {
      const that = this
      let token = ''
      const authInfo = localStorage.getItem('authInfo')
      if (authInfo) {
        const {
          Authorization
        } = JSON.parse(authInfo)
        token = Authorization // 让每个请求携带自定义 token 请根据实际情况自行修改
      }
      if (!token) {
        return
      }
      getAction('/user/token/info', {
        token
      }).then(res => {
        const { success, data } = res
        if (success) {
          const { businessDto } = data
          if (!businessDto) {
            this.$confirm({
              title: '提示',
              content: h => <div>未完善用户信息, 请先完善信息</div>,
              okText: '去完善',
              onOk() {
                that.$router.push({
                  path: '/businessManagement/companyInfo'
                })
              },
              onCancel() {
                console.log('Cancel')
              },
              class: 'test'
            })
          } else {
            this.$router.push({
              path: '/PublishingRequirements',
              query: {
                formType: 'product'
                // packagingMaterial: this.info.packagingMaterial
              }
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.workSpaceHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 24px 17px 32px;
  background-color: #fff;
  height: 64px;
  position: relative;

  // &::after {
  //   display: block;
  //   content: '';
  //   width: calc(100% - 72px);
  //   border-bottom: 1px solid #EFEFEF;
  //   position: absolute;
  //   bottom: 0;
  // }

  .left-icon {
    font-size: 15px;

    .anticon {
      cursor: pointer;
      color: #262626;
    }
  }

  .right-info {
    color: #262626;
    font-weight: 500;
    font-family: 'PingFang SC';
    display: flex;
    grid-gap: 32px 32px;
    align-items: center;

    .send-btn {
      background-color: #FFF2EA;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FF6026;
      font-size: 12px;
      font-weight: 500;
      width: 80px;
      height: 30px;
      cursor: pointer;
    }

    .nav-link {
      // padding-right: 15px;
      // border-right: 1px solid #efefef;
      cursor: pointer;
      height: 16px;
      display: flex;
      align-items: center;
    }

    .item {
      display: flex;
      align-items: center;
      padding-left: 5px;
      cursor: pointer;

      .label {
        margin: 0 4px;
      }
    }
  }
}

span.link {
  color: #E3E3E3;
}

.split-line {
  width: 1px;
  height: 20px;
  background: #EFEFEF;
  margin: 0 21px;
}

.link-text {
  color: #131212;
  cursor: pointer;
  position: relative;
  line-height: 24px;
}

::v-deep .ant-badge-multiple-words {
  padding: 0 6px;
  right: -7px;
}

::v-deep .ant-badge-count {
  padding: 0 6px;
  right: -12px;
}

.backToHome {
  height: 100%;
}

.sendInfo {
  height: 100%;
}

.notice-areas {
  height: 100%;
}
</style>
