<!--
 * @Description: 跳转导航组件
 * @Author: zhang zhen
 * @Date: 2023-02-08 16:39:09
 * @LastEditors: zhang zhen
 * @LastEditTime: 2023-07-21 13:45:43
 * @FilePath: /page-sass/src/components/tools/formNavigateModal.vue
-->
<template>
  <a-modal
    :visible="visible"
    @cancel="handleCancel"
    :footer="null"
    centered
    :width="604"
    dialogClass="formNavigateModalCover"
    :bodyStyle="{
      padding: '32px',
      height: '418px'
    }"
  >
    <h3 class="title">选择采购需求</h3>
    <div class="info-box">
      <div class="info-box-item">
        <div class="info-box-item-left">
          <img src="~@/assets/img_1.jpg" alt="" class="icon" />
          <div class="info-box-cover">
            <div class="subTitle">寻找生产商需求</div>
            <div class="desc">已有配材或样品时使用</div>
          </div>
        </div>
        <a-button class="action-btn" @click="handleNavigatePage('product')">发布需求</a-button>
      </div>
      <div class="info-box-item">
        <div class="info-box-item-left">
          <img src="~@/assets/img_2.jpg" alt="" class="icon" />
          <div class="info-box-cover">
            <div class="subTitle">寻找配材需求</div>
            <div class="desc">已有设计方案时使用</div>
          </div>
        </div>
        <a-button class="action-btn" @click="handleNavigatePage('package')">发布需求</a-button>
      </div>
      <div class="info-box-item">
        <div class="info-box-item-left">
          <img src="~@/assets/img_3.jpg" alt="" class="icon" />
          <div class="info-box-cover">
            <div class="subTitle">寻找设计需求</div>
            <div class="desc">需要整体设计方案时使用</div>
          </div>
        </div>
        <a-button class="action-btn" @click="handleNavigatePage('plan')">发布需求</a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'FormNavigateModal',
  data() {
    return {
      visible: false
    }
  },
  methods: {
    handleOpenView() {
      this.visible = true
    },
    handleNavigatePage(formType) {
      this.$router.push({
        path: '/PublishingRequirements',
        query: {
          formType
        }
      })
    },
    handleCancel() {
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped>
h3.title {
  color: #131212;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 28px;
}
.ant-btn {
  & + .ant-btn {
    margin-top: 8px;
  }
}
.info-box {
  padding: 24px 32px;
  &-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-left {
      display: flex;
      .icon {
        border-radius: 50%;
        width: 64px;
        height: 64px;
      }
      .info-box-cover {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-left: 14px;
        .subTitle {
          font-size: 16px;
          line-height: 22px;
          font-weight: 500;
          color: #131212;
        }
        .desc {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #979797;
        }
      }
    }
    &:not(:last-of-type) {
      margin-bottom: 24px;
    }
    .action-btn {
      width: 120px;
      height: 38px;
      color: #FF6026;
      background: #FFF2EA;
      border: 1px solid #FF7D2F;
    }
  }
}
</style>
