<!--
 * @Description: 头部组件
 * @Author: zhang zhen
 * @Date: 2023-02-03 09:06:30
 * @LastEditors: zhang zhen
 * @LastEditTime: 2023-10-28 14:54:07
 * @FilePath: /page-sass/src/components/page/GlobalHeader.vue
-->
<template>
  <div class="header-container">
    <a-layout-header>
      <!-- 标题 -->
      <div class="layout-header-left">
        <img src="~@/assets/home/logo.png" alt />
      </div>
      <SearchCenter v-show="needSearch || needSearchBar" />

      <div class="layout-header-right">
        <a-popconfirm
          placement="bottom"
          overlayClassName="coverSelector"
          :destroyTooltipOnHide="true"
          @visibleChange="handleChangeVisible"
        >
          <div slot="icon"></div>
          <div class="picker topPicker" slot="title">
            <a-cascader
              :field-names="{ label: 'regionName', value: 'regionId', children: 'children' }"
              :options="regionOptions"
              :load-data="loadData"
              placeholder="请选择省市"
              allowClear
              change-on-select
              style="width: 188px;"
              :getPopupContainer="coverContainer"
              v-model="selectedRegion"
              @change="handleChangeLocation"
            />
          </div>
          <div class="location">
            <span>{{ regionStr }}</span>
            <img src="~@/assets/home/downjt.png" style="margin-left: 20px;cursor: pointer;" />
          </div>
        </a-popconfirm>

        <span class="line">|</span>

        <router-link tag="div" to="/" class="navigation-name" style="cursor: pointer;">首页</router-link>

        <span class="line">|</span>

        <template v-if="hasLogin">
          <UserMenus iconType="dark" />
          <router-link tag="span" to="/workSpace" class="font-16 m-l-8">我的工作台</router-link>
        </template>
        <router-link tag="span" to="/login" class="font-15 link-page" v-else>亲,请登录，<a>免费注册</a></router-link>
        <a-button type="primary" class="action-btn" @click="handleJumpPage">
          <img src="~@/assets/exchange.png" alt="" class="icon">
          <span>首页</span>
        </a-button>
      </div>

    </a-layout-header>
    <!-- == 'homePage' || name == 'searchResult' -->
    <SearchBar v-if="pageName == 'homePage'" />
    <SearchBar placeholder="请搜索工厂名称/所在地/开始找工厂" v-else-if="pageName == 'searchResult'" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import UserMenus from '../tools/userMenus.vue'
import SearchCenter from '../tools/searchCenter.vue'

import SearchBar from '@/components/tools/searchBar.vue'
import { getAction } from '@/api/manage'
export default {
  name: 'GlobalHeader',
  props: {
    needSearchBar: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SearchCenter,
    UserMenus,
    SearchBar
  },
  data() {
    return {
      needSearch: false,
      regionOptions: [],
      location: {
        regionId: undefined,
        regionIdName: '',
        regionIdTwo: undefined,
        regionIdTwoName: ''
      },
      pageName: 'homePage',
      regionList: [],
      regionIdTwoList: [],
      selectedRegion: ['310000000000'],
      regionStr: '上海市',
      coverContainer: () => document.querySelector('.topPicker')
    }
  },
  watch: {
    $route(to, form, next) {
      const { meta, name } = to
      this.needSearch = meta.needHeaderSearch
      this.pageName = name
      this.isLogin()
    }
  },
  computed: {
    ...mapState({
      hasLogin: state => state.user.isLogin,
      regionData: state => state.user.regionData
    })
  },
  created() {
    const { name } = this.$route
    this.pageName = name
  },
  methods: {
    handleJumpPage() {
      if (this.pageName == 'homePage') {
        this.$router.push('/requirementHome')
      } else {
        this.$router.push('/')
      }
    },
    loadRegionList() {
      getAction('/area/queryByLevel', {
        level: 1
      }).then(res => {
        const { success, data } = res
        if (success) {
          this.regionOptions = data.map(item => ({ ...item, isLeaf: false }))
          if (this.selectedRegion && this.selectedRegion.length) {
            let regionIdRoot = this.selectedRegion[0]
            let targetItem = this.regionOptions.find(i => i.regionId == regionIdRoot)
            this.handleInitData(targetItem)
          }
        }
      })
    },
    handleInitData(targetOption) {
      console.log(targetOption)
      const { regionId, regionName } = targetOption
      targetOption.loading = true
      getAction('/area/queryByParentIdList', {
        parentId: regionId
      }).then(res => {
        const { success, data } = res
        if (success) {
          targetOption.loading = false
          targetOption.children = data.map(item => ({ ...item, isLeaf: true }))
          this.$forceUpdate()
        }
      })
    },
    loadData(selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1]
      // load options lazily
      this.handleInitData(targetOption)
    },
    handleChangeLocation(value, selectedOptions) {
      console.log(selectedOptions)
      let str = selectedOptions.map(i => i.regionName)
      this.regionStr = str.join(' / ')
    },
    ...mapGetters(['isLogin']),
    // loadRegionList() {
    //   getAction('/area/queryByLevel', {
    //     level: 1
    //   }).then(res => {
    //     const { success, data } = res
    //     if (success) {
    //       this.regionList = data
    //     }
    //   })
    // },
    handleChangeRootId() {
      this.regionIdTwoList = []
      this.location.regionIdTwo = undefined
      this.location.regionIdName = this.regionList.find(i => i.regionId == this.location.regionId)['regionName']
      this.handleLoadCity('regionIdTwo')
    },
    handleUpdateView() {
      this.$forceUpdate()
    },
    handleSaveCity() {
      this.location.regionIdTwoName = this.regionIdTwoList.find(i => i.regionId == this.location.regionIdTwo)[
        'regionName'
      ]
    },
    handleLoadCity(key, needClean = true) {
      let parentId = ''
      if (key == 'regionIdTwo') {
        this.regionIdTwoList = []
        needClean && (this.location.regionIdTwo = undefined)
        parentId = this.location.regionId
      }
      getAction('/area/queryByParentIdList', {
        parentId
      }).then(res => {
        const { success, data } = res
        if (success) {
          this[`${key}List`] = data
        }
      })
    },
    handleChangeVisible(e) {
      if (e) {
        // this.location = Object.assign({}, this.regionData)
        this.loadRegionList()
        // this.location.regionId && this.handleLoadCity('regionIdTwo', false)
      }
    },
    // handleChooseLocation() {
    //   this.$store.commit('changeRegionData', { ...this.location })
    // }
  }
}
</script>

<style lang="less" scoped>
* {
  // color: @header-primary-color;
  // font-weight: 500;
  color: #131212 !important;
}
.header-container {
  // position: sticky;
  // top: 0;
  // left: 0;
  z-index: 24;
  background-color: white;
}
.line {
  padding: 0 15px;
  color: #E3E3E3!important;
}
.ant-layout-header {
  // background: @theme-primary-color;
  // color: @header-primary-color;
  background-color: white;
  color: #333 !important;
  width: 1288px;
  min-width: 1288px;
  margin: 0 auto;
  padding: 0!important;
  .flexLayout();
  .layout-header-left {
    .flexLayout(@justifyContent: flex-start);
    img {
      width: 90px;
    }
    .location {
      margin: 0 60px 0 120px;
      cursor: pointer;
    }
    .navigation-name {
      cursor: pointer;
    }
  }
  .header-title {
    // color: @header-primary-color;
    margin: 0;
    font-size: 18px;
    font-weight: bold;
  }
  .layout-header-right {
    .flexLayout();
    span {
      cursor: pointer;
    }
  }
}

.picker {
  width: 250px;
}
.m-l-8 {
  margin-left: 8px;
}

.link-page {
  color: #8C8C8C!important;
  a {
    color: #FF6026!important;
  }
}

.action-btn {
  margin-left: 20px;
  span {
    color: #fff!important;
    font-weight: 500;
  }
  .icon {
    width: 16px;
    margin-right: 6px;
  }
}
</style>
